import { Container, Row, Col } from "react-bootstrap";
import './banner.css';
import headerImg from '../assets/img/2025-branding/2025-LNYFA-Snake-Parade.png';
import 'animate.css';

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col className="d-none d-md-block" xs={12} md={6} xl={6}>
            <h1>2025</h1>
            <h1>Year</h1>
            <h1>of the</h1>
            <h1>Snake</h1>
          </Col>
          <Col className="d-none d-sm-block d-md-none" xs={12} md={6} xl={6}>
            <div className="heading">
              <h1>2025</h1>
              <h1>Year of the Snake</h1>
            </div>
          </Col>
          <Col className="d-sm-none" xs={12} md={6} xl={6}>
            <div className="heading">
              <h1>2025</h1>
              <h1>Year of the</h1>
              <h1>Snake</h1>
            </div>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <img className="snake-parade-image" src={headerImg} alt="Parade of people holding a large snake artwork" />
          </Col>
        </Row>
        <h2>We are grateful to be sponsored by our co-founding community partners: Q-Wave, GAPIMNY, and API Rainbow Parents</h2>
      </Container>
    </section>
  )
}
